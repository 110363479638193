import { Store } from 'vuex';
import Axios, { AxiosStatic } from 'axios';
import { RootState } from '@/types';
import { authenticationService } from '@/services/AuthenticationService';
import { NpfError } from '@/types';

export const addInterceptors = (axios: AxiosStatic, store: Store<RootState>) => {
  axios.interceptors.request.use(function (config) {
    store.dispatch('error/clearErrors');
    return config;
  });
  axios.interceptors.request.use(async (request) => {
    // Get token and update it to axios if it's new.
    try {
      const maxWaitCount = 100; // 5 seconds
      let waitCount: number = 0;

      while (authenticationService.isFetchingRefreshToken) {
        await new Promise((r) => setTimeout(r, 50));
        waitCount++;

        if (waitCount >= maxWaitCount) {
          throw new Error('Token refresh failed');
        }
      }

      const tokenResponse = await authenticationService.getToken();

      const newToken = `Bearer ${tokenResponse}`;

      if (newToken === '') {
        throw new Error('Token retrieve failed');
      }

      if (tokenResponse) {
        request.headers.Authorization = `Bearer ${tokenResponse}`;

        if (Axios.defaults.headers.Authorization !== newToken) {
          Axios.defaults.headers.Authorization = `Bearer ${tokenResponse}`;
        }
      }

      return request;
    } catch (error) {
      // No need to use error block at this time. Currently all authentication errors starts new login process.
      // There is no need to do nothing in here. If user was saving etc. all modifications are lost.
      // In here we could be saving something locally and use that data after new login but it's out of scope currently
      throw new Error('Token retrieve failed');
    }
  });
  axios.interceptors.response.use(
    function (config) {
      return config;
    },
    async function (error) {
      const npfError: NpfError = {
        error: undefined,
        correlationId: undefined,
        statusCode: undefined
      };
      // TODO: Make api errors more standardized
      npfError.error = '';

      if (error.response) {
        const tempErrorData =
          error.response.data?.text != undefined ? await error.response.data.text() : error.response.data;
        // if tempErrorData is JSON don't parse the data object
        const errorData =
          tempErrorData !== undefined && tempErrorData !== null && tempErrorData.constructor == Object
            ? tempErrorData
            : tempErrorData.length > 0
            ? JSON.parse(tempErrorData)
            : undefined;

        npfError.error += errorData?.message ? ` ${errorData.message}` : '';

        if (errorData === undefined) {
          return Promise.reject(error);
        } else if (errorData && errorData?.errors == undefined) {
          npfError.statusCode = errorData.statusCode;

          // Add traceId (correlationId) to error message
          if (errorData.traceId) {
            npfError.correlationId = errorData.traceId;
          }

          if (errorData.message && errorData.statusCode == 400) {
            npfError.error = errorData.message;
          }
        } else if (error.response.status == 409) {
          npfError.error =
            'An error occured while saving version. There is already a newer version saved. Please refresh the page.';
        } else {
          for (const e in errorData.errors) {
            errorData.errors[e].forEach((ee: any) => {
              store.dispatch('error/setError', { error: ee });
            });
          }

          return Promise.reject(error);
        }
      } else if (error.message === 'Network Error') {
        npfError.error = 'Connection to Nelprof failed - check internet connection';
      } else {
        npfError.error = 'Unknown error';
      }

      store.dispatch('error/setError', npfError);
      return Promise.reject(error);
    }
  );
};

export const axiosPlugin = (store: Store<RootState>) => {
  store.subscribe(({ type, payload }) => {
    switch (type) {
      case 'config/CONFIGURE':
        Axios.defaults.paramsSerializer = serializer;
        Axios.defaults.baseURL = payload.baseUrl;
        addInterceptors(Axios, store);
        break;
    }
  });
};

export function serializer(params: object): string {
  return Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value as string)}`)
    .join('&');
}
