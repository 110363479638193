export function isPositiveInBaseUnits(pressure: number | undefined, unit: string): boolean {
  return toBaseUnit(unit, pressure ?? 0) > 0;
}

const toBaseUnit = (unit: string, value: number): number => {
  const unitFactors = getUnitFactors(unit);

  if (!unitFactors) {
    return value;
  }

  return unitFactors.c1 * value + unitFactors.c2;
};

const getUnitFactors = (unit: string): UnitFactors | undefined => {
  const unitFactors = units.find((x) => x.unit === unit);

  if (!unit) {
    return undefined;
  } else if (!unitFactors) {
    return undefined;
  }

  return unitFactors;
};

interface UnitFactors {
  unit: string;
  c1: number;
  c2: number;
}

const units: UnitFactors[] = [
  {
    unit: 'atmA',
    c1: 1.0133,
    c2: 0
  },
  {
    unit: 'barA',
    c1: 1,
    c2: 0
  },
  {
    unit: 'barG',
    c1: 1,
    c2: 1.0133
  },
  {
    unit: 'ftH2OA',
    c1: 0.02989,
    c2: 0
  },
  {
    unit: 'ftH2OG',
    c1: 0.02989,
    c2: 1.0133
  },
  {
    unit: 'kg/cm2A',
    c1: 0.98,
    c2: 0
  },
  {
    unit: 'kg/cm2G',
    c1: 0.98,
    c2: 1.0133
  },
  {
    unit: 'kPaA',
    c1: 0.01,
    c2: 0
  },
  {
    unit: 'kPaG',
    c1: 0.01,
    c2: 1.0133
  },
  {
    unit: 'mH2OA',
    c1: 0.09806,
    c2: 0
  },
  {
    unit: 'mH2OG',
    c1: 0.09806,
    c2: 1.0133
  },
  {
    unit: 'mmHgA',
    c1: 0.00133,
    c2: 0
  },
  {
    unit: 'MPaA',
    c1: 10,
    c2: 0
  },
  {
    unit: 'MPaG',
    c1: 10,
    c2: 1.0133
  },
  {
    unit: 'psiA',
    c1: 0.068948,
    c2: 0
  },
  {
    unit: 'psiG',
    c1: 0.068948,
    c2: 1.0133
  }
];
